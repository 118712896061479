import {api} from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    user: {},
    notes: []
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },

    setTodos(state, payload) {
      state.user.profile.todos = payload;
    },
    pushTodo(state, payload) {
      if (!state.user.profile.todos)
        state.user.profile.todos = [];

      state.user.profile.todos.push(payload);
    },
    deleteTodo(state, id) {
      const index = state.user.profile.todos.indexOf(state.user.profile.todos.find(todo => todo.id === id));

      state.user.profile.todos.splice(index, 1);
    },
    patchTodo(state, {id, payload}) {
      const index = state.user.profile.todos.indexOf(state.user.profile.todos.find(todo => todo.id === id));
      Object.assign(state.user.profile.todos[index], payload);
    },

    setNotes(state, payload) {
      state.user.profile.notes = payload;
    },
    pushNote(state, payload) {
      if (!state.user.profile.notes)
        state.user.profile.notes = [];

      state.user.profile.notes.push(payload);
    },
    deleteNote(state, id) {
      const index = state.user.profile.notes.indexOf(state.user.profile.notes.find(note => note.id === id));

      state.user.profile.notes.splice(index, 1);
    },
    patchNote(state, {id, payload}) {
      const index = state.user.profile.notes.indexOf(state.user.profile.notes.find(note => note.id === id));
      Object.assign(state.user.profile.notes[index], payload);
    },

    setSelections(state, payload) {
      state.user.profile.selections = payload;
    },
    setTodaysSelections(state, payload) {
      state.user.profile.todaysSelections = payload;
    },

    pushSelection(state, payload) {
      if (!state.user.profile.selections)
        state.user.profile.selections = [];

      state.user.profile.selections.push(payload);
    },
    deleteSelection(state, index) {
      const id = state.user.profile.selections[index].id;
      const deletedSelection = JSON.parse(JSON.stringify(state.user.profile.selections[index]));

      state.user.profile.selections.splice(index, 1);

      state.user.profile.selections.forEach(selection => {
        if (selection.element_id === deletedSelection.element_id && selection.start_offset >= deletedSelection.end_offset) {
          selection.start_offset += deletedSelection.end_offset;
          selection.end_offset += deletedSelection.end_offset;
        }
      });
    },
    patchSelection(state, {index, payload}) {
      Object.assign(state.user.profile.selections[index], payload);
    },

    like(state, {type, action, object}) {
      switch (action) {
        case "add":
          state.user.profile[`favorite_${type}`].push(object);
          break;
        case "remove":
          state.user.profile[`favorite_${type}`].splice(state.user.profile[`favorite_${type}`].indexOf(object), 1);
          break;
        default:
          throw(`Unexpected action ${action}`);
      }
    },
    complete(state, {type, action, object}) {
      switch (action) {
        case "add":
          state.user.profile[`completed_${type}`].push(object);
          break;
        case "remove":
          state.user.profile[`completed_${type}`].splice(state.user.profile[`completed_${type}`].indexOf(object), 1);
          break;
        default:
          throw(`Unexpected action ${action}`);
      }
    }
  },
  actions: {
    async initUser(context) {
      const res = (await api.get("users/user/")).data;

      context.commit("setUser", res);
    },

    async postTodo(context, payload) {
      const data = (await this.$api.post("users/user/todo-item/", payload)).data;

      context.commit("pushTodo", data);

      return data.id;
    },
    async patchTodo(context, {payload, id}) {
      context.commit("patchTodo", {payload, id});

      await this.$api.patch(`users/user/todo-item/${id}/`, payload);
    },
    async deleteTodo(context, id) {
      context.commit("deleteTodo", id);

      await this.$api.delete(`users/user/todo-item/${id}/`);
    },
    async fetchTodosBy(context, query) {
      context.commit("setTodos", (await this.$api.get(`users/user/todo-item/?${query}`)).data)
    },


    async postNote(context, payload) {
      context.commit("pushNote", (await this.$api.post("users/user/note/", payload)).data);
    },
    async patchNote(context, {payload, id}) {
      context.commit("patchNote", {id, payload});

      await this.$api.patch(`users/user/note/${id}/`, payload);
    },
    async deleteNote(context, id) {
      context.commit("deleteNote", id);

      await this.$api.delete(`users/user/note/${id}/`);
    },
    async fetchNotesBy(context, query) {
      context.commit('setNotes', (await this.$api.get(`users/user/note/?${query}`)).data);
    },


    //Nezaujíma nas managovanie store stavu po refactore selectionu. Preto su tu iba api calls
    async postSelection(context, payload) {
      const data = (await this.$api.post("users/user/selection/", payload)).data;
      return data.id;
    },
    async patchSelection(context, {element_id, payload}) {
      await this.$api.patch(`users/user/selection/${element_id}/`, payload);
    },
    async deleteSelection(context, element_id) {
      const promises = [];

      promises.push(this.$api.delete(`users/user/selection/${element_id}/`));

      await Promise.all(promises);
    },
    async fetchSelectionsBy(context, query) {
      context.commit(query ? "setTodaysSelections" : "setSelections", (await this.$api.get(`users/user/selection/?${query}`)).data);
    },

    async like(context, {type, action, object}) {
      context.commit("library/like", {payload: action === "add", id: object, type}, {root: true});

      //Typ musí byť vždy plurál
      if (type.charAt(type.length - 1) !== "s")
        type = `${type}s`;

      const beforeCommitUser = context.state.user;
      context.commit("like", {type, action, object});

      try {
        await context.dispatch("manageLikedAndCompleted", {type, action, object, managed: "liked"});
      } catch {
        context.commit("setUser", beforeCommitUser);
      }
    },
    async complete(context, {type, action, object}) {
      context.commit("library/complete", {payload: action === "add", id: object, type}, {root: true});

      if (type.charAt(type.length - 1) !== "s")
        type = `${type}s`;

      const beforeCommitUser = context.state.user;
      context.commit("complete", {type, action, object});

      try {
        await context.dispatch("manageLikedAndCompleted", {type, action, object, managed: "completed"});
      } catch {
        context.commit("setUser", beforeCommitUser);
      }
    },
    async manageLikedAndCompleted(context, {type, action, object, managed}) {
      await this.$api.post(`users/user/${type}/manage-completed-and-liked/`, {
        action,
        object,
        managed
      });
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  }
}