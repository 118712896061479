<template>
  <div class="overlay-wrapper" :class="{hide: !show}">
    <div class="d-flex justify-content-end header p-4">
      <div v-if="!loading">
        <svg @click="$emit('close')" fill="#00A9FF" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
             class="bi bi-x-lg cursor-pointer align-self-center" viewBox="0 0 16 16">
          <path
              d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
        </svg>
      </div>

      <div v-else>
        <div class="spinner-border spinner-border-sm text-lighter-blue"></div>
      </div>
    </div>

    <div class="overlay p-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Overlay",
  props: {
    show: Boolean,
    loading: Boolean
  },
  watch: {
    show(to) {
      if (to)
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
      else
        document.getElementsByTagName("body")[0].style.overflow = "visible";
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.overlay-wrapper {
  position: fixed;
  left: 50%;
  z-index: 9999;

  width: 100%;
  top: 100%;
  height: 97%;

  &, & .header {
    border-radius: 40px 40px 0 0 !important;
  }

  transition: transform .5s;

  @media(max-width: 576px) {
    top: 100% !important;
  }

  transform: translate(-50%, -100%);

  &.hide {
    display: block !important;
    transform: translate(-50%, 0);
  }

  &.hide {
    display: none;
  }

  background: white !important;
  border-radius: 10px;

  box-shadow: 0 0 10px #d7d7d7;

  & .header {
    cursor: default;
    height: 40px;
    background: white;
  }

  & .overlay {
    overflow-x: hidden;
    overflow-y: auto !important;
    height: calc(100% - 53px);
  }
}
</style>