<template>
  <div>
    <div class="go-back text-gray d-flex cursor-pointer" v-if="!withoutLink" @click="$router.go(-1)">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-chevron-left"
           viewBox="0 0 16 16">
        <path fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
      </svg>

      <div class="align-self-center">{{ !prevRoute?.meta.label ? prevName : prevRoute?.meta.label}}</div>
    </div>
    <h2 class="font-weight-black text-left">
      <slot></slot>
    </h2>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SectionHeader",
  props: {
    prevName: String,
    withoutLink: {type: Boolean}
  },
  computed: {
    ...mapGetters({
      prevRoute: "getPreviousRoute"
    })
  },
}
</script>

<style lang="scss">
@import "src/scss/variables";

.go-back {
  transition: color .3s;
  width: fit-content;

  & svg path {
    transition: fill .3s;
  }

  & div {
    line-height: 1;
  }

  &:hover {
    color: $lightest-blue !important;

    & svg path {
      fill: $lightest-blue !important;
    }
  }
}
</style>