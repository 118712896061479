export default {
    state() {
        return {
            currentSession: null,
            currentExerciseSession: null,
            currentPause: null,

            sessions: [],

            allTrainingSessions: []
        }
    },
    namespaced: true,

    mutations: {
        setAllTrainingSessions(state, payload) {
            state.allTrainingSessions = payload;
        },

        setSessions(state, payload) {
            state.sessions = payload;
            const currentSession = payload?.find(s => !s.ended_at);

            if (currentSession) {
                state.currentSession = currentSession;

                const ongoingExerciseSession = currentSession.exercise_sessions.find(s => !s.ended_at);

                if (ongoingExerciseSession) {
                    state.currentExerciseSession = ongoingExerciseSession;

                    const ongoingPause = ongoingExerciseSession.pauses.find(p => !p.ended_at);

                    if (ongoingPause)
                        state.currentPause = ongoingPause;
                }
            }
        },
        appendSession(state, payload) {
            state.sessions.push(payload);
        },
        setCurrentSession(state, payload) {
            state.currentSession = payload;
        },
        patchCurrentSession(state, payload) {
            Object.assign(state.currentSession, payload);
        },

        appendExerciseSessionsInCurrentTrainingSession(state, payload) {
            state.currentSession.exercise_sessions.push(payload);
        },
        setCurrentExerciseSession(state, payload) {
            state.currentExerciseSession = payload;
        },
        patchCurrentExerciseSession(state, payload) {
            Object.assign(state.currentExerciseSession, payload);
        },

        appendPauseInCurrentExerciseSession(state, payload) {
            state.currentExerciseSession.pauses.push(payload);
        },

        setCurrentPause(state, payload) {
            state.currentPause = payload;
        },
        patchCurrentPause(state, payload) {
            Object.assign(state.currentPause, payload);
        },
    },
    actions: {
        async fetchAllTrainingSessions(context) {
            const data = (await this.$api.get("library/training_session/?expand=true")).data;

            context.commit("setAllTrainingSessions", data);
        },

        //Metóda, kt. zjednocuje všetky akcie, kt. sa týkajú vytvárania itemov, kt. majú fk v payloade a dedia z BaseTimedItemu na BE
        async createBaseTimedItem(context, {
            url,
            payload,
            appendMutation,
            currentMutation
        }) {
            context.commit(currentMutation, {...payload, started_at: new Date().toISOString()});

            const data = (await this.$api.post(url, payload)).data;

            context.commit(currentMutation, data);
            context.commit(appendMutation, data);

            return data;
        },
        async finishBaseTimedItem(context, {
            url,
            patchMutation,
            setMutation,
        }) {
            context.commit(patchMutation, {
                ended_at: new Date().toISOString()
            });
            context.commit(setMutation, null);

            await this.$api.patch(url, {
                ended_at: new Date().toISOString()
            });
        },

        async createSession(context, payload) {
            const session = await context.dispatch("createBaseTimedItem", {
                url: "library/training_session/",
                payload,
                appendMutation: "appendSession",
                currentMutation: "setCurrentSession"
            });

            const ongoingExerciseSession = session.exercise_sessions.find(s => !s.ended_at);

            //Toto je defaultný exercise session, kt. sa vytvorí vždy pri vytvorení Training session
            if (ongoingExerciseSession)
                context.commit("setCurrentExerciseSession", ongoingExerciseSession);
        },
        async finishCurrentSession(context) {
            context.commit("setCurrentExerciseSession", null);
            context.commit("setCurrentPause", null);

            await context.dispatch("finishBaseTimedItem", {
                url: `library/training_session/${context.getters.getCurrentSession.id}/`,
                patchMutation: "patchCurrentSession",
                setMutation: "setCurrentSession"
            });
        },

        async createExerciseSession(context, payload) {
            await context.dispatch("createBaseTimedItem", {
                url: "library/exercise_session/",
                payload,
                appendMutation: "appendExerciseSessionsInCurrentTrainingSession",
                currentMutation: "setCurrentExerciseSession"
            });
        },
        async finishCurrentExerciseSession(context) {
            await context.dispatch("finishBaseTimedItem", {
                url: `library/exercise_session/${context.getters.getCurrentExerciseSession.id}/`,
                patchMutation: "patchCurrentExerciseSession",
                setMutation: "setCurrentExerciseSession"
            });
        },

        async createPause(context, payload) {
            const currentExerciseSession = JSON.parse(JSON.stringify(context.getters.getCurrentExerciseSession));
            currentExerciseSession.series++;

            context.commit("setCurrentExerciseSession", currentExerciseSession);

            await context.dispatch("createBaseTimedItem", {
                url: "library/pause/",
                payload,
                appendMutation: "appendPauseInCurrentExerciseSession",
                currentMutation: "setCurrentPause"
            });
        },
        async finishCurrentPause(context) {
            await context.dispatch("finishBaseTimedItem", {
                url: `library/pause/${context.getters.getCurrentPause.id}/`,
                patchMutation: "patchCurrentExerciseSession",
                setMutation: "setCurrentPause"
            });
        }
    },
    getters: {
        getCurrentSession(state) {
            return state.currentSession;
        },
        getCurrentExerciseSession(state) {
            return state.currentExerciseSession;
        },
        getCurrentPause(state) {
            return state.currentPause;
        },
        getAllTrainingSessions(state) {
            return state.allTrainingSessions;
        }
    }
}