<template>
  <div :id="id" class="placeholder">
    &nbsp;
  </div>
</template>

<script>
export default {
  name: "Skeleton",
  props: {
    height: String,
    width: String,
    borderRadius: String,
    id: String
  },
  mounted() {
    if (this.id) {
      const el = document.getElementById(this.id);

      el.style.width = this.width;
      el.style.height = this.height;
      el.style.borderRadius = this.borderRadius;
    }
  }
}
</script>

<style scoped>
@keyframes bgAnimate {
  0% {
    background-position: 50% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.placeholder {
  background-image: linear-gradient(
      to right,
      #d6d7d8 0%,
      #e2e3e4 10%,
      #d6d7d8 20%,
      #d6d7d8 100%
  );
  background-size: 200% 100%;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
  0 2px 4px -2px rgb(0 0 0 / 0.1);
  animation: bgAnimate 1.2s linear infinite;
}
</style>