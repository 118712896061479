<template>
  <div class="overlay" @click="emitClose" v-if="show">
    <div class="m-modal text-left">
      <div v-if="custom">
        <slot></slot>
      </div>
      <div v-else>
        <div class="h5 font-weight-bold">{{ title }}</div>
        <hr>
        <div class="body mb-3">
          {{ content }}
        </div>

        <button class="btn btn-primary" @click="$emit('close')">Zatvoriť</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    show: Boolean,
    content: String,
    custom: Boolean
  },
  methods: {
    emitClose(e) {
      if(e.target.classList.contains("overlay"))
        this.$emit("close");
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/scss/variables";

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($smoke, 0.5);
}

.m-modal {
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px;
  border-radius: 15px;
  box-shadow: 3px 3px 7px 1px $gray-500;
  background: #fff;

  max-width: 400px;
  width: 100%;

  z-index: 9999!important;
}
</style>