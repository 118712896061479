export default {
    mutations: {
        patchObject(state, {type, id, payload, index}) {
            console.log(payload);

            if (!index)
                index = state[type].indexOf(
                    state[type].find(o => o.id === id)
                );

            if (index !== -1)
                Object.assign(
                    state[type][index],
                    payload
                );
        },

        appendObject(state, {type, payload}) {
            state[type].push(payload);
        },

        deleteObject(state, {type, id}) {
            const index = state[type].indexOf(
                state[type].find(o => o.id === id)
            );

            if (index !== -1)
                state[type].splice(index, 1);
        }
    },

    actions: {
        async patchBook(context, {id, payload}) {
            const data = (await this.$api.patch(`library/books/${id}/`, payload)).data;

            context.commit("patchObject", {type: "books", id, payload: data});
        },
        async postBook(context, payload) {
            const data = (await this.$api.post("library/books/", payload)).data;

            context.commit("appendObject", {type: "books", payload: data});
        },
        async deleteBook(context, id) {
            context.commit("deleteObject", {type: "books", id});

            await this.$api.delete(`library/books/${id}/`);
        },

        async patchMeditation(context, {id, payload}) {
            const data = (await this.$api.patch(`library/meditations/${id}/`, payload)).data;

            context.commit("patchObject", {type: "meditations", id, payload: data});
        },
        async postMeditation(context, payload) {
            const data = (await this.$api.post("library/meditations/", payload)).data;

            context.commit("appendObject", {type: "meditations", payload: data});
        },
        async deleteMeditation(context, id) {
            context.commit("deleteObject", {type: "meditations", id});

            await this.$api.delete(`library/meditations/${id}/`);
        },

        async patchCourse(context, {id, payload}) {
            const data = (await this.$api.patch(`library/courses/${id}/`, payload)).data;

            context.commit("patchObject", {type: "courses", id, payload: data});
        },
        async postCourse(context, payload) {
            const data = (await this.$api.post("library/courses/", payload)).data;

            context.commit("appendObject", {type: "courses", payload: data});
        },
        async deleteCourse(context, id) {
            context.commit("deleteObject", {type: "courses", id});

            await this.$api.delete(`library/courses/${id}/`);
        }
    },
}