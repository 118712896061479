<template>
  <div class="tooltip-wrapper p-2" :class="{show, big, visible}"
       :style="`top: ${position.y}px; left: ${position.x}px;`">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  data() {
    return {
      visible: false,
    }
  },
  props: {
    position: Object,
    show: Boolean,
    big: Boolean
  },
  watch: {
    show(to) {
      if (!to)
        setTimeout(() => {
          this.visible = false;
        }, 300);
      else
        this.visible = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.tooltip-wrapper {
  opacity: 0;
  transition: opacity 0.3s;
  width: 400px;
  display: none;

  border: 2px solid $gray;
  border-radius: 50px;

  &.big {
    border-radius: 15px !important;
  }

  position: absolute;
  background: rgba($white, 0.9);

  &.show {
    opacity: 1;
  }

  &.visible {
    display: block !important;
  }

  & .content {

  }
}
</style>