import {createRouter, createWebHistory} from 'vue-router';

import store from "../store/index";

const routes = [
    {
        path: "/",
        redirect: {name: "Dashboard"}
    },
    {
        path: '/app',
        name: 'App',
        meta: {requiresLogin: true},
        component: () => import("../views/AppView.vue"),
        children: [
            {
                path: "dashboard",
                name: "Dashboard",
                component: () => import("../containers/Dashboard"),
                meta: {label: "Dashboard"}
            },

            {
                path: "partner-dashboard",
                name: "PartnerDashboard",
                component: () => import("../containers/affiliate/PartnerDashboard.vue"),
                meta: {label: "Dashboard partnera"}
            },

            {
                path: "library",
                name: "Library",
                component: () => import("../containers/Library.vue"),

                children: [
                    {
                        path: "books",
                        name: "Books",
                        component: () => import("../containers/books/Books"),
                    },
                    {
                        path: "books/detail/:id",
                        name: "BookDetail",
                        component: () => import("../containers/books/BookDetail"),
                    },
                    {
                        path: "books/read/:id",
                        name: "BookReading",
                        props: {type: "books"},
                        component: () => import("../containers/BaseLibraryReading")
                    },

                    {
                        path: "articles",
                        name: "Articles",
                        component: () => import("../containers/articles/Articles")
                    },
                    {
                        path: "article/:id",
                        name: "ArticleReading",
                        props: {type: "courses"},
                        component: () => import("../containers/BaseLibraryReading")
                    },

                    {
                        path: "meditations",
                        name: "Meditations",
                        component: () => import("../containers/meditations/Meditations")
                    },
                    {
                        path: "meditation/:id",
                        name: "MeditationReading",
                        props: {type: "meditations"},
                        component: () => import("../containers/BaseLibraryReading")
                    },

                    {
                        path: "trainings",
                        name: "Trainings",
                        component: () => import("../containers/trainings/Trainings")
                    },
                    {
                        path: "trainings/training/:id",
                        name: "TrainingReading",
                        component: () => import("../containers/trainings/TrainingReading")
                    },
                    {
                        path: "trainings/principle/:id",
                        name: "TrainingPrincipleReading",
                        component: () => import("../containers/articles/ArticleReading")
                    },
                    {
                        path: "games",
                        name: "Games",
                        component: () => import("../containers/games/Games.vue")
                    },
                    {
                        path: "games/play/:game",
                        name: "PlayGame",
                        props: true,
                        component: () => import("../containers/games/PlayGame.vue")
                    }
                ]
            },
            {
                path: "diary",
                name: "Diary",
                component: () => import("../views/Diary.vue"),
                children: [
                    {
                      path: "overview",
                      name: "Overview",
                      component: () => import("../containers/diary/Overview.vue")
                    },
                    {
                        path: "favorites",
                        name: "Favorites",
                        props: {view: "liked"},
                        component: () => import("../containers/diary/FavoritesAndCompleted"),
                        meta: {label: "Obľúbené"}
                    },
                    {
                        path: "completed",
                        name: "Completed",
                        props: {view: "completed"},
                        component: () => import("../containers/diary/FavoritesAndCompleted"),
                        meta: {label: "Dokončené"}
                    },
                    {
                        path: "custom-trainings",
                        name: "CustomTrainings",
                        component: () => import("../containers/diary/CustomTrainings.vue"),
                        meta: {label: "Vlastné tréningy"}
                    },
                ]
            },
            {
                path: "account",
                name: "Account",
                component: () => import("../containers/account/Account"),
            }
        ]
    },
    {
        path: "/auth",
        name: "Auth",
        component: () => import("../views/Auth"),
        children: [
            {
                path: "login",
                name: "Login",
                component: () => import("../containers/auth/Login.vue"),
            },
            {
                path: "register",
                name: "Register",
                component: () => import("../containers/auth/Register.vue"),
            },
            {
                path: "partner-register",
                name: "PartnerRegister",
                component: () => import("../containers/auth/PartnerRegister.vue")
            },
        ]
    },
    {
        path: "/admin",
        name: "Admin",
        meta: {
          admin: true
        },
        component: () => import("../views/Admin"),
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const fromHistory = Boolean(savedPosition);

        if (fromHistory && store.state.routerHistory.length > 0) {
            store.state.routerHistory.splice(-1, 1);
        } else {
            store.state.routerHistory.push(from);
        }

        return savedPosition || { x: 0, y: 0 };
    },
})

router.beforeEach(async (to, from, next) => {
    let interval;

    await new Promise(resolve => {
        interval = setInterval(() => {
            if (store.getters.getInitCompleted)
                resolve();
        }, 100);
    });

    clearInterval(interval);

    if(to.matched.some(record => record.meta.admin)) {
        if (!store.getters["user/getUser"].is_staff)
            return next({name: "Login"})
    } else if (to.matched.some(record => record.meta.requiresLogin)) {
        if (!store.getters["user/getUser"])
            return next({name: "Login"})
    }

    return next();
});


export default router
