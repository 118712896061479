import admin from "@/store/mixins/admin";

export default {
  namespaced: true,

  state() {
    return {
      books: [],
      courses: [],
      meditations: [],
      trainings: [],
      exercises: [],
      principles: [],

      book: null,
      course: null,
      meditation: null,
      training: null,
      principle: null,

      bookCategories: [],
    }
  },

  mutations: {
    ...admin.mutations,

    setBooks(state, payload) {
      state.books = payload;
    },
    setBook(state, payload) {
      state.book = payload;
    },

    setBookCategories(state, payload) {
      state.bookCategories = payload;
    },
    handleAllBookCategory(state, payload) {
      if (payload)
        state.bookCategories.forEach(category => {
          category.active = false;
        });

      state.bookCategories[0].active = payload;
    },

    setCourses(state, payload) {
      state.courses = payload;
    },
    setCourse(state, payload) {
      state.course = payload;
    },

    setTrainingPrinciple(state, payload) {
      state.principle = payload;
    },
    setTrainingPrinciples(state, payload) {
      state.principles = payload;
    },

    setMeditations(state, payload) {
      state.meditations = payload;
    },
    setMeditation(state, payload) {
      state.meditation = payload;
    },

    setTrainings(state, payload) {
      state.trainings = payload;
    },
    setTraining(state, payload) {
      state.training = payload;
    },

    setExercises(state, payload) {
      state.exercises = payload;
    },

    like(state, {payload, id, type}) {
      //ak patchujeme medzi objektmi, vs ak patchujeme konkretny objekt
      if (Array.isArray(state[type]))
        state[type].find(o => o.id === id).liked = payload;
      else state[type].liked = payload;
    },
    complete(state, {payload, id, type}) {
      if (Array.isArray(state[type]))
        state[type].find(o => o.id === id).completed = payload;
      else state[type].completed = payload;
    },

    deleteCustomTraining(state, id) {
      state.trainings.splice(state.trainings.indexOf(id), 1);
    }
  },

  actions: {
    ...admin.actions,

    async fetchBookCategories(context) {
      let categories = (await this.$api.get("library/categories/")).data;

      categories = categories.map(obj => ({...obj, active: false}));

      categories.forEach(cat => {
        delete Object.assign(cat, {display_name: cat["1"]})["1"];
        delete Object.assign(cat, {value: cat["0"]})["0"];
      })

      context.commit("setBookCategories", [{
        display_name: "Všetky",
        value: "ALL",
        active: true
      }, ...categories]);
    },
    async fetchBooks(context, {id, filter, sortBy, show} = {id: null, filter: null, sortBy: "title", show: "all"}) {
      if (!id) {
        let query = "?";

        context.getters.getBookCategories.filter(category => category.active).forEach((category) => {
          if (category.value !== "ALL")
            query += `categories=${category.value}&`;
        });

        if (show && show !== "all")
          query += `show=${show}&`;

        if (filter)
          query += `search=${filter}&`;

        if (sortBy)
          query += `sort_by=${sortBy}`;

        const results = (await this.$api.get(`library/books/${query}`)).data;
        results.sort((a, b) => a.order - b.order);

        context.commit("setBooks", results);
      } else context.commit("setBook", (await this.$api.get(`library/books/${id}/`)).data);
    },

    async fetchCourses(context, {id, show, category} = {id: null, show: "all", category: "FZ"}) {
      if (!id) {
        let query = "?";

        if (category)
          query += `category=${category}&`;

        if (show)
          query += `show=${show}`;

        const result = (await this.$api.get(`library/courses/${query}`)).data;

        //Zoradíme podľa orderu
        result.sort((a, b) => a.order - b.order);

        context.commit("setCourses", result);
      } else context.commit("setCourse", (await this.$api.get(`library/courses/${id}/`)).data);
    },

    async fetchMeditations(context, {id, search, show = "all"} = {id: null, search: null, show: "all"}) {
      if (!id) {
        let query = "?";

        if (search)
          query += `search=${search}&`;

        if (show && show !== "all")
          query += `show=${show}`;


        const result = (await this.$api.get(`library/meditations/${query}`)).data;
        //Zoradíme podľa orderu
        result.sort((a, b) => a.order - b.order);

        context.commit("setMeditations", result);
      } else context.commit("setMeditation", (await this.$api.get(`library/meditations/${id}/`)).data);
    },

    async fetchTrainings(context, {id, show, categories, is_custom, createdByMe} = {
      id: null,
      show: "all",
      categories: [],
      is_custom: false,
      createdByMe: false,
    }) {
      if (!id) {
        let query = "?";

        if (show && show !== "all")
          query += `show=${show}&`;

        if (categories && !categories.includes("all"))
          categories.forEach(cat => {
            query += `categories=${cat}&`
          });

        if (!is_custom)
          is_custom = false;

        query += `is_custom__exact=${is_custom.toString().charAt(0).toUpperCase() + is_custom.toString().substring(1)}&`;

        if (createdByMe)
          query += `created_by__exact=${context.rootGetters["user/getUser"].user}`;

        context.commit("setTrainings", (await this.$api.get(`library/trainings/${query}`)).data);
      } else context.commit("setTraining", (await this.$api.get(`library/trainings/${id}/`)).data);
    },

    async fetchExercises(context, {show, search, categories} = {show: "all", search: null, categories: []}) {
      let query = "?";

      if (show && show !== "all")
        query += `show=${show}&`;

      if (search)
        query += `search=${search}`;

      if (categories && !categories.includes("all"))
        categories.forEach(cat => {
          query += `categories=${cat}&`
        });

      context.commit("setExercises", (await this.$api.get(`library/exercises/${query}`)).data);
    },

    async fetchTrainingPrinciples(context, {id, show, category, search} = {
      id: null,
      show: "all",
      category: "",
      search: null
    }) {
      if (!id) {
        let query = "?";

        if (category)
          query += `category=${category}&`;

        if (show)
          query += `show=${show}&`;

        if (search)
          query += `search=${search}`;

        context.commit("setTrainingPrinciples", (await this.$api.get(`library/training_principles/${query}`)).data);
      } else context.commit("setTrainingPrinciple", (await this.$api.get(`library/training_principles/${id}/`)).data);
    },

    async deleteCustomTraining(context, id) {
      const training = context.getters.getTrainings.find(training => training.id === id);

      context.commit("deleteCustomTraining", id);

      try {
        await this.$api.delete(`library/trainings/${id}/`);
      } catch (e) {
        context.commit("library/setTrainings", [
          ...context.getters["library/getTrainings"],
          training
        ]);
      }
    },
  },

  getters: {
    getBooks(state) {
      return state.books;
    },
    getBook(state) {
      return state.book;
    },
    getBookCategories(state) {
      return state.bookCategories;
    },
    getBookCategoryByValue(state) {
      return (value) => state.bookCategories.find(category => category.value === value)
    },

    getCourses(state) {
      return state.courses;
    },
    getCourse(state) {
      return state.course;
    },

    getMeditations(state) {
      return state.meditations;
    },
    getMeditation(state) {
      return state.meditation;
    },

    getTrainings(state) {
      return state.trainings;
    },
    getTraining(state) {
      return state.training;
    },

    getTrainingPrinciples(state) {
      return state.principles;
    },
    getTrainingPrinciple(state) {
      return state.principle;
    },

    getExercises(state) {
      return state.exercises;
    },
  }
}