import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/global.scss";
import "bootstrap";
import "vue-select/dist/vue-select.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';

import vSelect from "vue-select";
import Modal from "./components/global/Modal";
import Tooltip from "@/components/global/Tooltip";
import SectionHeader from "@/components/SectionHeader";
import Overlay from "@/components/global/Overlay.vue";
import Skeleton from "@/components/global/Skeleton.vue";

import api from "./plugins/axios";

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

const app = createApp(App).use(router).use(api).use(VueVideoPlayer);

store.$api = app.config.globalProperties.$api;

app.use(store).component("skeleton", Skeleton).component("v-select", vSelect).component("section-header", SectionHeader).component("v-slider", VueSlider).component("tooltip", Tooltip).component("modal", Modal).component("overlay", Overlay).mount('#app');
