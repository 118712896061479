import axios from "axios";
import router from "@/router";

const _axios = axios.create({
    baseURL: "https://api.kurzzivota.sk/",
    // baseURL: "http://localhost:8000/",
    withCredentials: true,
});

_axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (error.response && (error.response.status === 401 || !error.response.status)) {
        if(error.config?.url === "auth/token/refresh/" || error.config?.url === "auth/token/access/")
            return Promise.reject(error);

        if(error.response?.data?.code === "user_not_found")
            return Promise.reject(error);

        try {
            await _axios.post("auth/token/refresh/");
            const r = await _axios.request(error.config);
            return Promise.resolve(r);
        } catch (e) {
            console.log(e)

            if (e.response.status === 401 && !window.location.href.includes("register"))
                router.push({name: "Login"});

            return Promise.reject(error);
        }
    }

    return Promise.reject(error);
});

export const api = _axios;

export default {
    install: (app) => {
        app.config.globalProperties.$api = _axios;
    }
}