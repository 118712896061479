import {createStore} from 'vuex'
import library from "./modules/library";
import user from "./modules/user";
import training from "@/store/modules/training";

export default createStore({
    state: {
        modal: {
            state: false,
            title: "",
            content: ""
        },

        initCompleted: false,

        routerHistory: [],
    },
    getters: {
        getModal(state) {
            return state.modal;
        },
        getInitCompleted(state) {
            return state.initCompleted;
        },
        getPreviousRoute(state) {
            const historyLen = state.routerHistory.length;
            if (historyLen === 0) return null;
            return state.routerHistory[historyLen - 1];
        },
    },
    mutations: {
        setModal(state, payload) {
            state.modal = payload;
        },
        setInitCompleted(state, payload) {
            state.initCompleted = payload;
        },
        setPrevRoute(state, payload) {
            state.prevRoute = payload;
        }
    },
    actions: {},
    modules: {
        library,
        user,
        training
    }
})
